




































































import axios from 'axios';
import Vue from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';
import * as firebase from 'firebase';
import moment from 'moment';
import * as accounting from 'accounting/accounting';
import 'moment/locale/tr';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/firestore';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable, of, throwError, fromEvent, merge } from 'rxjs';

moment.locale('tr');
// Construct the broadcast channel between service worker.
const broadcast = new BroadcastChannel('broadcast');

// Set audio source
const audio = new Audio('/cash.mp3');

// Generate months
const _tempDate = (new Date());
const _months = Array(12).fill(0).map((_, i) => {
  _tempDate.setMonth(i);
  return {
    index: i,
    cursor: i + 1,
    title: _tempDate.toLocaleString("default", { month: 'long' })
  };
});

export default Vue.extend({
  data() {
    return {
      months: _months,
      firestore: null,
      loading: false,
      messages: [],
      accounts: [],
      items: [],
      listener: null,
      segment: {
        year: (new Date()).getFullYear(),
        month: (new Date()).getMonth() + 1
      }
    }
  },
  computed: {
    currentSegment() {
      const segment = this.segment;
      return segment.year.toString() + "-" + segment.month.toString().padStart(2, "0");
    },
    currentSegmentTitle() {
      return this.months.find(m => m.cursor === this.segment.month).title + ", " + this.segment.year.toString();
    },
    progress() {
      const currDate = new Date();
      const nowSegment = parseFloat(currDate.getFullYear().toString() + "." + (currDate.getMonth() + 1).toString());
      const currSegment = parseFloat(this.segment.year.toString() + "." + this.segment.month.toString());
      switch (true) {
        case nowSegment === currSegment:
          return (100 * (new Date()).getDate()) / (new Date(this.segment.year, this.segment.month, 0)).getDate();
        case nowSegment < currSegment:
          return 0;
        default: return 100;
      }
    },
    mostPriceItems() {
      return this.items.slice(0).sort((a,b) => {
        if (a.price > b.price) return 1;
        if (a.price < b.price) return -1;
        return 0;
      }).reverse();
    },
    mostCountItems() {
      return this.items.slice(0).sort((a,b) => {
        if (a.count > b.count) return 1;
        if (a.count < b.count) return -1;
        return 0;
      }).reverse();
    }
  },
  methods: {
    reduce(items, segment) {
	return items.reduce((sum, item) => sum + item[segment], 0);
    },
    goBack() {
      this.changeSegment(this.segment.month - 1, this.segment.year);
    },
    goForward() {
      this.changeSegment(this.segment.month + 1, this.segment.year);
    },
    goNow() {
      this.changeSegment((new Date()).getMonth() + 1, (new Date()).getFullYear());
    },
    isNow() {
      return this.currentSegment === (new Date()).getFullYear().toString() + "-" + ((new Date()).getMonth() + 1).toString().padStart(2, "0");
    },
    formatMoney(m) {
      return accounting.formatMoney(m, { symbol: "TL",  format: "%v %s", decimal : ",", thousand : "." });
    },
    getAccountName(hid){
      const a = this.accounts.find(a => a.id.toString() === hid.toString());
      return !!a ? a.account_name : 'Bulunamadı!';
    },
    fromNow(date){
      return moment(date).fromNow();
    },
    playSound(){
      try {
        audio.play();
      } catch (err){
        console.error(err);
      }
    },
    showMessage(m) {
      this.playSound();
      m['created'] = new Date(m.created._seconds * 1000);
      m['id'] = Date.now().toString() + (Math.random() * 10000).toString();
      this.messages.unshift(m);
      if (this.messages.length > 100) this.messages = this.messages.slice(0, 99);
    },
    changeSegment(m, y) {
      switch (true) {
        case (m < 1):
          y = y - 1;
          m = 12;
          break;
        case(m > 12):
          y = y + 1;
          m = 1;
      }
      if (this.segment.year === y && this.segment.month === m) return;
      this.segment = {
        year: y,
        month: m
      };
      this.buildUI();
    },
    buildUI() {
      if (!!this.listener) {
        this.listener.unsubscribe();
        this.listener = null;
      }
      this.loading = true;
      const detector = new Observable(s => this.firestore.collection("statistics/" + this.currentSegment + "/accounts").onSnapshot(s));
      this.listener = detector
          .pipe(map(d => {
            const changes = [];
            d.forEach(d => changes.push(Object.assign(d.data(), { hizmet_id: d.id })));
            return changes;
          }))
          .subscribe(list => {
            this.items = list;
            this.loading = false;
          });
    }
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<any>): any {
    // Login redirect
    fromPromise(axios.get('/api/ping'))
      .pipe(
          map(() => undefined),
          switchMap(() => fromPromise<undefined | string>(new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(user => !!user ? resolve(undefined) : reject('firebase'))
          }))),
          catchError(err => {
            if (err === 'firebase' || (!!err && !!err.response && err.response.status === 401)) return of('/login');
            return throwError(err);
          })
      )
      .subscribe(link => next(link), err => console.error(err));
  },
  mounted() {

    // Create messaging api
    const messaging = firebase.messaging();

    // Use public key
    messaging.usePublicVapidKey('BO7BlDVjC2fks0aEc7vUdnxNDDw9BXpUJVQsGSS7BP4w3WFE_sGiRjQYiWG18ZDo-ML0gd6528oYZ0LUOJ9eRcM');

    // Register topic
    const onTokenRefresh = new Observable(s => {
      messaging.onTokenRefresh(s);
      s.next();
    });
    onTokenRefresh
      .pipe(
          switchMap(() => fromPromise(messaging.getToken())),
          tap(t => console.info('FCM Token alındı', t)),
          switchMap(token => axios.post('/api/token/register', { token: token.trim() }))
      ).subscribe(() => console.info('Topic kaydı tamamlandı'), err => console.error('Topic kaydı tamamlanamadı', err));

    // Listen messages
    const fgHandler = new Observable((s) => messaging.onMessage(s)).pipe(
      filter((p) => !!p && !!p.data && !!p.data.snapshot),
      map((p) => JSON.parse(p.data.snapshot))
    );

    // Listen background messages.
    const bgHandler = fromEvent(broadcast, "message").pipe(map((e) => e.data));

    // Concat streams.
    const handlers = merge(fgHandler, bgHandler);

    // Show the notifications.
    handlers
      .pipe(filter((p) => p.tags.indexOf("order") > -1))
      .subscribe(this.showMessage);

    // Get accounts
    axios.get('/api/accounts').then(r => this.accounts = r.data);

    // Firestore
    this.firestore = firebase.firestore();

    // Build IO
    this.buildUI();
  }
});
